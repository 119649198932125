@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 47.4% 11.2%;
   
      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;
   
      --popover: 0 0% 100%;
      --popover-foreground: 222.2 47.4% 11.2%;
   
      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
   
      --card: 0 0% 100%;
      --card-foreground: 222.2 47.4% 11.2%;
   
      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;
   
      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;
   
      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;
   
      --destructive: 0 100% 50%;
      --destructive-foreground: 210 40% 98%;
   
      --ring: 215 20.2% 65.1%;
   
      --radius: 0.5rem;
    }
   
    .dark {
      --background: 224 71% 4%;
      --foreground: 213 31% 91%;
   
      --muted: 223 47% 11%;
      --muted-foreground: 215.4 16.3% 56.9%;
   
      --accent: 216 34% 17%;
      --accent-foreground: 210 40% 98%;
   
      --popover: 224 71% 4%;
      --popover-foreground: 215 20.2% 65.1%;
   
      --border: 216 34% 17%;
      --input: 216 34% 17%;
   
      --card: 224 71% 4%;
      --card-foreground: 213 31% 91%;
   
      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 1.2%;
   
      --secondary: 222.2 47.4% 11.2%;
      --secondary-foreground: 210 40% 98%;
   
      --destructive: 0 63% 31%;
      --destructive-foreground: 210 40% 98%;
   
      --ring: 216 34% 17%;
   
      --radius: 0.5rem;
    }
  }
   
  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
      font-feature-settings: "rlig" 1, "calt" 1;
    }
  }

  @layer components {
    .button-gradient {
      @apply ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 
             disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 inline-flex items-center justify-center px-6 py-2 
             border-0 rounded-full text-sm font-medium text-white bg-gradient-to-l from-blue-500 to-purple-600 shadow-lg 
             hover:from-purple-500 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
             transition-all duration-300 ease-in-out transform hover:scale-105;
    }

    .mobile-menu-item {
      @apply -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900
             hover:bg-gradient-to-l hover:from-blue-500/10 hover:to-purple-600/10
             transition-all duration-200 ease-in-out;
    }
  }

  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gradient-to-b from-blue-500 to-purple-600 rounded-full;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply from-purple-500 to-blue-600;
  }

  /* Smooth scrolling for the entire page */
  html {
    scroll-behavior: smooth;
  }

  /* Focus styles for better accessibility */
  :focus-visible {
    @apply outline-none ring-2 ring-purple-500 ring-offset-2;
  }

  /* Mobile-first media queries */
  @media (max-width: 640px) {
    .container {
      @apply px-4;
    }
  }

  /* Animation utilities */
  .animate-fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }

  .animate-slide-in {
    animation: slideIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }